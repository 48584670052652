import styled from 'styled-components'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import BaseButton, {
  ButtonElement,
  ButtonSize,
  ButtonVariant,
} from 'components/atoms/button'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { Colors as COLORS } from '@typeform/ginger/dist/constants/colors'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${spacing(2)};
`

export const SocialButtons = styled.div`
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing(2)};
`

export const Button = styled(BaseButton).attrs(() => ({
  size: ButtonSize.Medium,
  variant: ButtonVariant.Secondary,
}))`
  flex: 0 0 100%;
  ${ButtonElement} {
    display: flex;
    align-items: center;
    border-color: ${COLORS.Grey300};
    &:hover {
      border-color: ${COLORS.Grey600};
    }
  }
  ${mediaQueries.sm`
      flex: 1;
    `}
`

export const Link = styled(BaseButton).attrs(() => ({
  size: ButtonSize.Medium,
  variant: ButtonVariant.Tertiary,
}))`
  ${ButtonElement} {
    display: flex;
    align-items: center;
    color: ${COLORS.Grey800};
  }
`

export const Icon = styled.img`
  width: 22px;
  margin-right: ${spacing(1)};
`

export const Divider = styled.hr`
  flex: 0 0 100%;
  height: 0;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: ${COLORS.Grey300};
  overflow: visible;
  margin: ${spacing(2, 0, 1, 0)};
  text-align: center;
  &::after {
    content: attr(data-content);
    font-weight: 500;
    position: relative;
    top: -13px;
    padding: ${spacing(0, 2)};
    color: ${COLORS.Grey600};
    background: ${COLORS.White};
  }
`
