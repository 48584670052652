import { useCallback, useEffect, useRef, useState } from 'react'

type TRect = Omit<DOMRect, 'toJSON'> &
  Pick<HTMLElement, 'offsetHeight' | 'offsetWidth'>

const useResizeObserver = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any | null>(null)
  const { current: element } = ref
  const [rect, setRect] = useState<TRect>({
    width: 0,
    height: 0,
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    y: 0,
    x: 0,
    offsetWidth: 0,
    offsetHeight: 0,
  })

  const onResize = useCallback<ResizeObserverCallback>(
    ([{ contentRect }]) => {
      if (element && contentRect) {
        setRect({
          width: contentRect.width,
          height: contentRect.height,
          left: contentRect.left,
          top: contentRect.top,
          bottom: contentRect.bottom,
          right: contentRect.right,
          y: contentRect.y,
          x: contentRect.x,
          offsetWidth: (element as HTMLElement).offsetWidth,
          offsetHeight: (element as HTMLElement).offsetHeight,
        })
      }
    },
    [element]
  )

  useEffect(() => {
    if (!element) return

    const resizeObserver = new ResizeObserver(onResize)
    resizeObserver.observe(element)

    return () => {
      resizeObserver.disconnect()
    }
  }, [element, onResize])

  return {
    ref,
    ...rect,
  }
}

export default useResizeObserver
