import {
  LOTTIE_ANIMATION,
  MEDIA_EMBED,
  MEDIA_IMAGE,
  MEDIA_IMAGE_WITH_TYPEFORM_POPUP,
  MEDIA_TYPEFORM,
  MEDIA_VIDEO,
  MEDIA_VIDEOASK,
  MEDIA_WISTIA,
  MEDIA_YOUTUBE,
} from 'constants/content-types'

import React, { memo } from 'react'
import dynamic from 'next/dynamic'
import LazyLoad from 'components/atoms/lazy-load/'

import { TContentfulMediaProps, TLazyLoadWrapperProps } from './types'
import ContentfulMediaEmbed from './contentful-media-embed'

const ContentfulMediaTypeform = dynamic(
  () => import('./contentful-media-typeform')
)

const VideoAskPlayer = dynamic(
  () => import('components/molecules/videoask-player/videoask-player')
)
const OptimizedWistiaPlayer = dynamic(
  () => import('components/optimized-media/optimized-wistia-player')
)
const YoutubePlayer = dynamic(
  () => import('components/molecules/youtube-player')
)

const OptimizedVideo = dynamic(
  () => import('components/optimized-media/optimized-video')
)
const OptimizedImage = dynamic(
  () => import('components/optimized-media/optimized-image')
)

const LottiePlayer = dynamic(async () => {
  const { Player } = await import('@lottiefiles/react-lottie-player')

  return Player
})

const ImageWithTypeformPopup = dynamic(
  () => import('components/atoms/image-with-typeform-popup')
)

const LazyLoadWrapper = ({ aboveTheFold, children }: TLazyLoadWrapperProps) => {
  if (aboveTheFold) {
    return <>{children}</>
  }

  return <LazyLoad>{children}</LazyLoad>
}

const ContentfulMedia = ({
  aboveTheFold,
  mediaProps,
}: TContentfulMediaProps) => {
  switch (mediaProps.contentType) {
    case MEDIA_IMAGE: {
      const {
        contentType,
        url,
        width,
        height,
        title,
        description,
        ...imgProps
      } = mediaProps

      return (
        <OptimizedImage
          src={url}
          width={width || undefined}
          height={height || undefined}
          alt={title || description}
          aboveTheFold={aboveTheFold}
          {...imgProps}
        />
      )
    }
    case MEDIA_VIDEO: {
      const { poster, mp4, webm, aspectRatio } = mediaProps

      return (
        <OptimizedVideo
          preload={aboveTheFold}
          poster={poster?.url}
          mp4={mp4?.url}
          webm={webm?.url}
          aspectRatio={aspectRatio}
        />
      )
    }
    case MEDIA_YOUTUBE: {
      return <YoutubePlayer {...mediaProps} lazyLoad={!aboveTheFold} />
    }
    case MEDIA_WISTIA: {
      return (
        <OptimizedWistiaPlayer aboveTheFold={aboveTheFold} {...mediaProps} />
      )
    }
    case MEDIA_VIDEOASK: {
      const { title, ...videoAskPlayerProps } = mediaProps

      return (
        <LazyLoadWrapper aboveTheFold={aboveTheFold}>
          <VideoAskPlayer
            name={title}
            iFrameTitle={title}
            {...videoAskPlayerProps}
          />
        </LazyLoadWrapper>
      )
    }
    case LOTTIE_ANIMATION: {
      return (
        <LazyLoadWrapper aboveTheFold={aboveTheFold}>
          <LottiePlayer {...mediaProps} />
        </LazyLoadWrapper>
      )
    }
    case MEDIA_TYPEFORM: {
      return (
        <LazyLoadWrapper aboveTheFold={aboveTheFold}>
          <ContentfulMediaTypeform {...mediaProps} standAlone />
        </LazyLoadWrapper>
      )
    }
    // We will most likely depreciate this kind of media therefore
    // it is not supported for new Media and it can only come from legacy module models
    case MEDIA_IMAGE_WITH_TYPEFORM_POPUP: {
      const { typeformId, url, title, description } = mediaProps
      return (
        <LazyLoadWrapper aboveTheFold={aboveTheFold}>
          <ImageWithTypeformPopup typeformId={typeformId}>
            <OptimizedImage
              src={url}
              alt={title || description}
              width='100%'
              height='auto'
              aboveTheFold={aboveTheFold}
            />
          </ImageWithTypeformPopup>
        </LazyLoadWrapper>
      )
    }

    case MEDIA_EMBED: {
      return (
        <LazyLoadWrapper aboveTheFold={aboveTheFold}>
          <ContentfulMediaEmbed {...mediaProps} />
        </LazyLoadWrapper>
      )
    }
    default:
      return null
  }
}

export default memo(ContentfulMedia)
