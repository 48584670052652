import { TExperiments } from 'types/experiments'

import { TContentfulEntry, TEntry } from './entry-model.types'

class Entry<T extends TContentfulEntry> {
  entry: T
  experiments?: TExperiments
  constructor(entry: T, experiments?: TExperiments) {
    this.entry = entry
    this.experiments = experiments
  }

  get id() {
    return this.entry?.sys?.id
  }

  get spaceId() {
    return this.entry?.sys?.space?.sys?.id
  }

  get environmentId() {
    return this.entry?.sys?.environment?.sys?.id
  }

  get fields(): T['fields'] {
    return this?.entry?.fields || {}
  }

  get contentTypeMeta() {
    return this?.entry?.sys?.contentType || null
  }

  get contentType() {
    // If an entry references another unpublished entry, the linked entry will have no sys.contentType property.
    if (!this.contentTypeMeta) {
      return null
    }

    if (this.contentTypeMeta.sys?.linkType !== 'ContentType') {
      return null
    }
    return this.contentTypeMeta.sys.id
  }

  toJSON(): TEntry {
    return {
      fields: this.fields,
      contentType: this.contentType,
    }
  }
}

export default Entry
