import {
  RICH_TEXT_MODULE,
  IMAGE_ACCORDION_MODULE,
  TYPEFORM_TEMPLATE_MODULE,
  TYPEFORM_EMBED_MODULE,
  MLP_BLOCKS_EXPERIMENT,
  HEADLINE_MODULE,
  MEDIA_TEXT_MODULE,
  MEDIA_IMAGE,
  HERO_MODULE,
  MEDIA_BIG_MODULE,
  CTA_MODULE,
  TOPIC_SOCIALS_SIGNUP_ACTION_BAR,
} from 'constants/content-types'
import { APPEARANCE } from 'constants/design'

import { useCallback } from 'react'
import memoize from 'memoize-one'
import { InView } from 'react-intersection-observer'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import Markdown from 'components/atoms/markdown'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { trackEvent, trackExperimentEvent } from 'components/tracking'
import { documentParseOptions } from 'utils/document-parse-options'
import { SectionTypeformEmbed } from 'components/landing-page/section/section.styles'
import VariationContainer from 'components/variation-container'
import ContentfulMedia from 'components/landing-page/contentful-media/contentful-media'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import SocialSignupActionBar from 'components/social-signup-action-bar'

import {
  COMPONENT_MAP,
  ABOVE_THE_FOLD_BLOCKS,
  HEADLINE_MODULE_SIZE,
} from './constants'

const renderRichTextModule = memoize((block, BlockComponent) => {
  if (!BlockComponent) {
    return null
  }

  const { content: document, ...blockProps } = block

  try {
    const content = documentToReactComponents(document, documentParseOptions)
    return (
      <BlockComponent
        key={blockProps.id}
        trackEvent={trackEvent}
        trackExperimentEvent={trackExperimentEvent}
        {...blockProps}
      >
        {content}
      </BlockComponent>
    )
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return null
  }
})

const renderEmbedsModules = memoize((block, BlockComponent) => {
  const { ...blockRest } = block

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <SectionTypeformEmbed ref={ref} hasCta={Boolean(block.cta?.text)}>
          {inView && (
            <BlockComponent
              key={block.id}
              trackEvent={trackEvent}
              trackExperimentEvent={trackExperimentEvent}
              {...blockRest}
            />
          )}
        </SectionTypeformEmbed>
      )}
    </InView>
  )
})

const getNewMediaProps = ({ media: mediaProps, aboveTheFold }) => {
  const { media, smallMedia } = mediaProps || {}
  const computedAboveTheFold = mediaProps?.aboveTheFold || aboveTheFold

  let renderedMediaProps = {}

  if (media) {
    renderedMediaProps.media = (
      <ContentfulMedia aboveTheFold={computedAboveTheFold} mediaProps={media} />
    )
  }

  if (smallMedia) {
    renderedMediaProps.smallMedia = (
      <ContentfulMedia
        aboveTheFold={computedAboveTheFold}
        mediaProps={smallMedia}
      />
    )
  }

  return renderedMediaProps
}

const getEyebrow = ({ eyebrow, aboveTheFold }) => {
  if (!eyebrow) {
    return undefined
  }

  const { title, url } = eyebrow

  if (!url) {
    return title
  }

  return (
    <ContentfulMedia
      contentType={MEDIA_IMAGE}
      aboveTheFold={aboveTheFold}
      mediaProps={eyebrow}
    />
  )
}

export const renderComponentsWithNewMediaModule = memoize(
  (block, BlockComponent) => {
    if (!BlockComponent) {
      return null
    }

    const { body: document, media, aboveTheFold, ...blockProps } = block

    try {
      const body =
        typeof document !== 'string'
          ? documentToReactComponents(document, documentParseOptions)
          : document
      return (
        <BlockComponent
          key={blockProps.id}
          trackEvent={trackEvent}
          trackExperimentEvent={trackExperimentEvent}
          {...blockProps}
          {...getNewMediaProps({ media, aboveTheFold })}
          eyebrow={getEyebrow(block)}
          body={body}
        />
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return null
    }
  }
)

export const renderHero = memoize(
  (
    {
      contentType,
      id,
      experiments,
      locale,
      query,
      appearance,
      actionBar,
      ...moduleProps
    },
    HeroComponent
  ) => {
    const handleHeroLinkClick = useCallback(
      ({ link }) => {
        if (link === actionBar.primaryLink) {
          trackExperimentEvent({
            name: 'hero_module_primary_cta_click',
          })
          return
        }

        trackExperimentEvent({
          name: 'hero_module_secondary_cta_click',
        })
      },
      [actionBar]
    )

    const { body, media, ...blockProps } = moduleProps

    try {
      let renderedBody = body

      if (contentType === HERO_MODULE) {
        renderedBody = <Markdown inline>{body}</Markdown>
      } else if (typeof body !== 'string') {
        renderedBody = documentToReactComponents(body, documentParseOptions)
      }

      let { customActionBar } = blockProps

      if (actionBar?.contentType === TOPIC_SOCIALS_SIGNUP_ACTION_BAR) {
        customActionBar = <SocialSignupActionBar />
      }

      return (
        <HeroComponent
          key={blockProps.id}
          trackEvent={trackEvent}
          trackExperimentEvent={trackExperimentEvent}
          locale={locale}
          query={query}
          {...blockProps}
          {...getNewMediaProps({ media, aboveTheFold: true })}
          actionBar={{
            ...actionBar,
            onClick: handleHeroLinkClick,
          }}
          customActionBar={customActionBar}
          eyebrow={getEyebrow(blockProps)}
          body={renderedBody}
          backgroundColor={
            moduleProps.background ??
            (appearance === APPEARANCE.DARK ? NeutralColors.Ink : undefined)
          }
          palette={
            moduleProps.palette ??
            (appearance === APPEARANCE.DARK
              ? Palette.Negative
              : Palette.Positive)
          }
        />
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return null
    }
  }
)

const renderMediaBigModule = memoize((block, BlockComponent) => {
  const { textColumns, media, aboveTheFold, ...blockProps } = block

  try {
    const renderedTextColumns = textColumns?.map?.(({ title, body }) => ({
      title,
      body: documentToReactComponents(body, documentParseOptions),
    }))

    return (
      <BlockComponent
        key={blockProps.id}
        trackEvent={trackEvent}
        trackExperimentEvent={trackExperimentEvent}
        {...blockProps}
        {...getNewMediaProps({ media, aboveTheFold })}
        textColumns={renderedTextColumns}
      />
    )
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return null
  }
})

const renderBlock = (block, locale, index, context) => {
  if (!block) {
    return null
  }
  const blockWithExtraProps = {
    ...block,
    aboveTheFold: index + 1 <= ABOVE_THE_FOLD_BLOCKS,
  }

  const BlockComponent = COMPONENT_MAP[block.contentType]

  if ([RICH_TEXT_MODULE, IMAGE_ACCORDION_MODULE].includes(block.contentType)) {
    return renderRichTextModule(blockWithExtraProps, BlockComponent)
  }

  if (block.contentType === MEDIA_BIG_MODULE) {
    return renderMediaBigModule(blockWithExtraProps, BlockComponent)
  }

  if ([MEDIA_TEXT_MODULE, CTA_MODULE].includes(block.contentType)) {
    return renderComponentsWithNewMediaModule(
      blockWithExtraProps,
      BlockComponent
    )
  }

  if (
    [TYPEFORM_TEMPLATE_MODULE, TYPEFORM_EMBED_MODULE].includes(
      block.contentType
    )
  ) {
    return renderEmbedsModules(blockWithExtraProps, BlockComponent)
  }

  if (block.contentType === MLP_BLOCKS_EXPERIMENT) {
    return (
      <VariationContainer {...blockWithExtraProps}>
        {renderBlock(block.variationEntry, locale, index, context)}
      </VariationContainer>
    )
  }

  if ([HEADLINE_MODULE].includes(block.contentType)) {
    blockWithExtraProps['titleSize'] = HEADLINE_MODULE_SIZE
  }
  return BlockComponent ? (
    <BlockComponent
      {...blockWithExtraProps}
      lazyLoadAssets={blockWithExtraProps.aboveTheFold}
      locale={locale}
      trackEvent={trackEvent}
      trackExperimentEvent={trackExperimentEvent}
      context={context}
    />
  ) : null
}

export default renderBlock
