import { QUOTE } from 'constants/content-types'

import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { get } from '@s-libs/micro-dash'
import dynamic from 'next/dynamic'
import { Picture } from 'components/atoms/images'
import QuoteModel from 'models/quote-model'

export const getEmbeddedAsset = node => {
  const isEmbeddedImage =
    node &&
    get(node.data, `target.fields.file.contentType`.split('.'), '').includes(
      'image'
    )
  if (isEmbeddedImage) {
    const imageData = get(node, 'data.target.fields'.split('.'), '')
    if (
      ['image/png', 'image/jpg', 'image/jpeg'].includes(
        imageData.file.contentType
      )
    ) {
      const srcSet = {
        srcSetWebP: `${imageData.file.url}?fm=webp`,
        srcSet: imageData.file.url,
        original: imageData.file.url,
      }
      const { width, height } = imageData.file.details.image
      return (
        <Picture
          alt={imageData.title}
          srcset={srcSet}
          width={width}
          height={height}
        />
      )
    }
    return null
  }
  return null
}

export const LINK_OPTIONS_PARAMETER = 'link-options'

// Maps allowed link options to react prop names
export const LINK_OPTIONS_TO_PROP_MAPPING = {
  hreflang: 'hrefLang',
  referrerpolicy: 'referrerPolicy',
  rel: 'rel',
  target: 'target',
}

/**
 * Using the rich text editor, anchor tag attributes can be specified in the format
 *
 * https://www.url.com?link-options=[hreflang=...,referrerpolicy=...,rel=...,target=...]
 *
 * This custom renderer processes those options and adds them to the anchor tag when present.
 */
export const getEmbeddedHyperlink = node => {
  const linkText = node?.content?.[0]?.value
  if (!linkText) {
    return null
  }

  const createHyperlink = (props = { href: node?.data?.uri }) => (
    <a {...props}>{linkText}</a>
  )

  let url
  try {
    url = new URL(node?.data?.uri)
  } catch (e) {
    // An error is thrown if the input string is not a valid URL.
    // A valid url will contain the protocol.
    // https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
    return createHyperlink()
  }

  if (!url.searchParams.has(LINK_OPTIONS_PARAMETER)) {
    return createHyperlink()
  }

  const optionsParam = url.searchParams.get(LINK_OPTIONS_PARAMETER)
  const optionsParamValue = optionsParam.match(/^\[(.*)\]$/)
  const optionsDefinition = optionsParamValue?.[1] || ''
  url.searchParams.delete(LINK_OPTIONS_PARAMETER)

  const linkOptions = Object.keys(LINK_OPTIONS_TO_PROP_MAPPING)

  // Convert provided link options into anchor tag props
  const props = optionsDefinition.split(',').reduce(
    (prev, option) => {
      const [splitKey = '', splitValue = ''] = option.split('=')
      const optionKey = splitKey.trim().toLowerCase()
      const optionValue = splitValue.trim()

      if (!linkOptions.includes(optionKey)) {
        return prev
      }

      const optionProp = LINK_OPTIONS_TO_PROP_MAPPING[optionKey]
      return { ...prev, [optionProp]: optionValue }
    },
    {
      href: url.href,
    }
  )

  return createHyperlink(props)
}

const embeddedComponentsMap = {
  [QUOTE]: dynamic(() => import('@typeform/ginger/dist/components/quote')),
}

const embeddedModelsMap = {
  [QUOTE]: QuoteModel,
}

const getEmbeddedEntry = node => {
  try {
    const { target } = node.data

    const contentType = target.sys.contentType.sys.id

    const EmbeddedComponent = embeddedComponentsMap[contentType]
    const embeddedInstance = new embeddedModelsMap[contentType](target)

    return (
      <EmbeddedComponent
        content={embeddedInstance.toJSON()}
        key={target.sys.id}
      />
    )
  } catch {
    return null
  }
}

export const documentParseOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: getEmbeddedAsset,
    [INLINES.HYPERLINK]: getEmbeddedHyperlink,
    [BLOCKS.EMBEDDED_ENTRY]: getEmbeddedEntry,
  },
}
