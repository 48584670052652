import {
  VARIATION_CONTAINER,
  VARIATION_SWITCHER,
} from 'constants/content-types'

import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  OptimizelyExperiment,
  OptimizelyVariation,
} from 'components/optimizely'

const VariationContainer = memo(props => {
  if (!props) {
    return null
  }

  const { children, activeExperiment } = props

  if (!activeExperiment) {
    return children
  }

  return (
    <OptimizelyExperiment experimentId={activeExperiment.id}>
      {/* Only one variation here because we already know which is the active one and which needs to be activated */}
      <OptimizelyVariation
        default
        variationId={activeExperiment.variationId}
        children={children}
      />
    </OptimizelyExperiment>
  )
})

VariationContainer.propTypes = {
  children: PropTypes.node,
  activeExperiment: PropTypes.shape({
    id: PropTypes.string,
    variationKey: PropTypes.string,
    variationId: PropTypes.string,
  }),
}

VariationContainer.defaultProps = {
  children: null,
  activeExperiment: undefined,
}

VariationContainer.displayName = VARIATION_CONTAINER

export default VariationContainer

export const VariationSwitcher = memo(
  ({
    experimentContentType,
    variationEntry,
    children: renderChildren,
    ...props
  }) => {
    const isExperiment = experimentContentType === props.contentType

    if (!isExperiment) {
      return renderChildren(props)
    }

    return (
      <VariationContainer {...props}>
        {renderChildren(variationEntry)}
      </VariationContainer>
    )
  }
)

VariationSwitcher.propTypes = {
  children: PropTypes.func.isRequired,
  variationEntry: PropTypes.object,
  experimentContentType: PropTypes.string,
  contentType: PropTypes.string,
}

VariationSwitcher.defaultProps = {
  experimentContentType: '',
  contentType: '',
  variationEntry: null,
}

VariationSwitcher.displayName = VARIATION_SWITCHER
