import styled from 'styled-components'
import { Container } from 'components/modified-kitt/container'
import {
  CTA_HEIGHT,
  TYPEFORM_EMBED_HEIGHT,
} from 'components/atoms/common/typeform/constants'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import {
  HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from '@typeform/ginger/dist/lib/constants'

const determineTypeformEmbedHeight = (hasCta, size) => {
  const ctaHeight = hasCta ? CTA_HEIGHT : 0
  const embedHeight = TYPEFORM_EMBED_HEIGHT[size] + ctaHeight
  return `${embedHeight}px`
}

export const FlatContainer = styled(Container)`
  background: ${({ background }) => background};
  border-radius: 0;
  padding: 32px 16px;

  ${mediaQueries.md`
    padding: 0 16px;
  `};
`

export const SectionHeading = styled.span`
  margin: 0;
  padding-bottom: 24px;
  line-height: 40px;
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.xLarge};
  text-align: ${({ align }) => align};
  color: ${({ color }) => color};
`

export const SectionDescription = styled.div`
  font-size: ${fontSize.medium};
  margin-bottom: 32px;
  text-align: ${({ align }) => align};
`

export const SectionContentWrapper = styled.div`
  max-width: ${({ containerSizeOverride }) => containerSizeOverride * 8}px;
  display: block;
  margin: 0 auto;
`

export const SectionTypeformEmbed = styled.section`
  min-height: ${({ hasCta }) => determineTypeformEmbedHeight(hasCta, 'xs')};

  ${mediaQueries.sm`
    min-height: ${({ hasCta }) => determineTypeformEmbedHeight(hasCta, 'sm')};
  `}

  ${mediaQueries.lg`
    min-height: ${({ hasCta }) => determineTypeformEmbedHeight(hasCta, 'lg')};
  `}
`

export const BlocksContainer = styled.div`
  font-size: ${fontSize.small};
  line-height: ${lineHeight.large};

  /* Using overflow clip ensures that sticky elements within landing pages function as intended */
  overflow-x: clip;
`

export const HeroModuleContainer = styled.div`
  padding-top: ${MOBILE_HEADER_HEIGHT}px;
  ${mediaQueries.md`
    padding-top: ${HEADER_HEIGHT}px;
  `}
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
`
