import Entry from './entry-model'
import Image from './image-model'
import Audio from './audio-model'

class Quote extends Entry {
  get author() {
    return this.fields.author
  }

  get position() {
    return this.fields.position
  }

  get avatar() {
    return this.fields.avatar ? new Image(this.fields.avatar).toJSON() : {}
  }

  get text() {
    return this.fields.text
  }

  get markdownText() {
    return this.fields.content
  }

  get audio() {
    return this.fields.audio ? new Audio(this.fields.audio).toJSON() : {}
  }

  get tweetable() {
    return this.fields.tweetable || false
  }

  get hashtags() {
    return this.fields.hashtags || []
  }

  toJSON() {
    return {
      contentType: super.contentType,
      author: this.author,
      position: this.position,
      avatar: this.avatar,
      text: this.text,
      markdownText: this.markdownText,
      audio: this.audio,
      tweetable: this.tweetable,
      hashtags: this.hashtags,
    }
  }
}

export default Quote
