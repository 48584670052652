import {
  PAGE_PROPS,
  PAGE_DEFAULT_PROPS,
  PRICING_PLAN_PROP_TYPE,
} from 'constants/prop-types'
import {
  MLP_HERO_EXPERIMENT,
  MLP_CTA,
  MLP_CTA_EXPERIMENT,
  PRICING_PLANS_MODULE,
} from 'constants/content-types'
import { TRY_PATH } from 'constants/url'
import { PROPERTY } from 'constants/tracking'
import { CELLO_REFERRAL_INVITE_LP_HERO } from 'constants/experiments'
import { APPEARANCE } from 'constants/design'

import { logError, logNotFound } from 'server/utils/logger'
import React, { Fragment, Suspense } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import DocumentHead from 'components/head'
import {
  BlocksContainer,
  HeroModuleContainer,
} from 'components/landing-page/section/section.styles'
import { VariationSwitcher } from 'components/variation-container'
import {
  getCommonSyncServerSideProps,
  getCommonAsyncServerSideProps,
} from 'utils/server'
import ContentfulService from 'services/contentful-service'
import { LAYOUT_TYPES as FOOTER_LAYOUT_TYPES } from '@typeform/ginger/dist/components/footer/constants'
import PricingService from 'services/pricing-service'
import { isExperimentVariationActive } from 'utils/optimizely'
import renderBlock, { ABOVE_THE_FOLD_BLOCKS } from 'utils/landing-page'
import { useOptimizelyContext } from 'components/optimizely'
import ErrorBoundary from 'components/error-boundary'
import { renderHero } from 'utils/landing-page/render-block'
import CelloLegalBanner from 'components/cello-legal-banner'
import SuspenseFallback from 'components/suspense-fallback'
import SocialSignupActionBar from 'components/social-signup-action-bar'

const FOOTER_LAYOUT_MAPPING = {
  minimal: FOOTER_LAYOUT_TYPES.COMPACT,
}

const Hero = dynamic(() => import('components/modules/hero-module'))
const ExitIntentSurvey = dynamic(() => import('components/exit-intent-survey'))

const LandingPageBelowFold = dynamic(() =>
  import(
    /* webpackChunkName: "landing-page-below-the-fold" */ 'components/landing-page/landing-page-below-fold'
  )
)

const buildCustomCta = (menuPrimaryLink = null) => {
  if (!menuPrimaryLink) {
    return {}
  }

  if (menuPrimaryLink.contentType === MLP_CTA) {
    const { text: label, url: link, contentType, ...rest } = menuPrimaryLink

    return {
      ...rest,
      label,
      link,
    }
  }

  if (menuPrimaryLink.contentType === MLP_CTA_EXPERIMENT) {
    return buildCustomCta(menuPrimaryLink.variationEntry)
  }

  return {}
}

const getCustomActionBar = experiments => {
  const isCelloReferralInviteLPHeroExperiment = isExperimentVariationActive({
    experiments,
    experimentId: CELLO_REFERRAL_INVITE_LP_HERO.ID,
    variationId: CELLO_REFERRAL_INVITE_LP_HERO.VARIATIONS.ON,
  })

  if (isCelloReferralInviteLPHeroExperiment) {
    return <SocialSignupActionBar />
  }

  return null
}

const LandingPage = ({
  pageContent,
  availableLocales,
  currentUrl,
  locale,
  query,
  path,
  pricingPlans,
  growthPricingPlans,
  discounts,
}) => {
  const {
    metaTags,
    blocks,
    closingModule: closingOrVariation,
    heroModule: heroOrVariation,
    appearance,
    exitIntentSurvey,
    menuPrimaryLink,
  } = pageContent

  const aboveTheFold = blocks.slice(0, ABOVE_THE_FOLD_BLOCKS)
  const belowTheFold = blocks.slice(ABOVE_THE_FOLD_BLOCKS)

  const { experiments } = useOptimizelyContext()

  return (
    <>
      <DocumentHead
        {...metaTags}
        currentUrl={currentUrl}
        availableLocales={availableLocales}
        ogLocale={locale}
        isUsingContentfulImageAsset={true}
      />
      <ErrorBoundary
        location='landing-pages'
        extraInfo={{ experiments, currentUrl }}
      >
        <VariationSwitcher
          {...menuPrimaryLink}
          experimentContentType={MLP_CTA_EXPERIMENT}
        >
          {/* Nothing needs to be rendered because that data gets handled in the buildCustomCta function
         and passed to the header through the appConfig object in getServerSideProps */}
          {() => null}
        </VariationSwitcher>
        {exitIntentSurvey && <ExitIntentSurvey id={exitIntentSurvey.formId} />}
        <BlocksContainer>
          {heroOrVariation && (
            <HeroModuleContainer backgroundColor={heroOrVariation.background}>
              <VariationSwitcher
                {...heroOrVariation}
                experimentContentType={MLP_HERO_EXPERIMENT}
              >
                {moduleProps =>
                  renderHero(
                    {
                      experiments,
                      locale,
                      query,
                      appearance,
                      customActionBar: getCustomActionBar(experiments),
                      ...moduleProps,
                    },
                    Hero
                  )
                }
              </VariationSwitcher>
            </HeroModuleContainer>
          )}

          {aboveTheFold.map((block, index) => {
            const module = renderBlock(block, locale, index, {
              path,
              pricingPlans,
              discounts,
              growthPricingPlans,
            })

            return <Fragment key={block.id}>{module}</Fragment>
          })}
          <Suspense fallback={<SuspenseFallback />}>
            <LandingPageBelowFold
              blocks={belowTheFold}
              locale={locale}
              path={path}
              pricingPlans={pricingPlans}
              growthPricingPlans={growthPricingPlans}
              discounts={discounts}
              closingModule={closingOrVariation}
            />
          </Suspense>
        </BlocksContainer>
        <CelloLegalBanner />
      </ErrorBoundary>
    </>
  )
}

export const getServerSideProps = async ctx => {
  const { query, params, locale } = ctx

  if (!params) {
    logNotFound({
      message: 'No params provided for Landing Page',
      additionalInfo: { query, params, locale, section: 'landingpage' },
    })
    return {
      notFound: true,
    }
  }

  try {
    const commonSyncServerSideProps = getCommonSyncServerSideProps(ctx)

    const pricingService = new PricingService()

    const [lp, commonAsyncServerSideProps] = await Promise.all([
      ContentfulService.getLandingPage({
        slugs: params.slugs,
        locale,
        experiments: commonSyncServerSideProps.experiments,
      }),
      getCommonAsyncServerSideProps(ctx, commonSyncServerSideProps),
    ])

    if (!lp) {
      logNotFound({
        message: 'No landing page data found in Land Page',
        additionalInfo: {
          query,
          params,
          locale,
          section: 'landingpage',
        },
      })
      return {
        notFound: true,
      }
    }

    if (lp.disabledLocalesList.includes(locale)) {
      logNotFound({
        message: 'Locale disabled for Land Page',
        additionalInfo: { query, params, locale, section: 'landingpage' },
      })
      return {
        notFound: true,
      }
    }

    if (lp.heroModule?.hasError) {
      logError({
        message: 'LandingPageModel: error while creating the HeroModule model',
        additionalInfo: { ...lp.heroModule.error },
      })
      lp.heroModule = null
    }

    let pricingPlans = null
    let growthPricingPlans = null
    let discounts = null
    const hasPricingModule = lp?.blocks?.some?.(
      block => block.contentType === PRICING_PLANS_MODULE
    )

    if (hasPricingModule) {
      // We don't want to block the page rendering if we can't fetch the pricing plans
      try {
        await pricingService.fetchPricing(
          commonSyncServerSideProps.userCountryCode,
          commonAsyncServerSideProps.bannerItems,
          true
        )

        pricingPlans = pricingService.getCorePlans()
        growthPricingPlans = pricingService.getGrowthPlans()
        discounts = pricingService.getDiscounts()
      } catch (error) {
        logError({
          message:
            'Error in landing page getServerSideProps - fetching pricing plans',
          error,
          additionalInfo: { section: 'landingpage' },
        })
      }
    }

    let lpWithTrackingConfig = lp
    if (lpWithTrackingConfig.slug?.startsWith?.(`${TRY_PATH}/`)) {
      lpWithTrackingConfig = {
        ...lpWithTrackingConfig,
        trackingConfig: {
          ...lpWithTrackingConfig.trackingConfig,
          viewPageSectionProps: {
            typeform_property: PROPERTY.LANDING_PAGE,
            ...lpWithTrackingConfig.trackingConfig?.viewPageSectionProps,
          },
        },
      }
    }

    return {
      props: {
        pageContent: lpWithTrackingConfig,
        ...commonSyncServerSideProps,
        ...commonAsyncServerSideProps,
        pricingPlans,
        growthPricingPlans,
        discounts,
        appConfig: {
          headerProps: {
            customCta: {
              ...commonSyncServerSideProps.appConfig.headerProps.customCta,
              ...buildCustomCta(lp.menuPrimaryLink),
            },
            enableMinimalMenu: lp.enableMinimalMenu,
            appearance: lp.appearance,
          },
          footerProps: {
            layoutType:
              FOOTER_LAYOUT_MAPPING[lp.footerStyle] ||
              FOOTER_LAYOUT_TYPES.DEFAULT,
          },
        },
        path: `/${params.slugs.join('/')}/`,
        query,
      },
    }
  } catch (error) {
    logError({
      message: 'Error in landing page getServerSideProps',
      error,
      additionalInfo: { section: 'landingpage' },
    })
    return {
      notFound: true,
    }
  }
}

LandingPage.propTypes = {
  ...PAGE_PROPS,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      contentType: PropTypes.string.isRequired,
    })
  ),
  closingModule: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    cta: PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }),
  metaTags: PropTypes.object,
  appearance: PropTypes.oneOf(Object.values(APPEARANCE)),
  exitIntentSurvey: PropTypes.shape({
    formId: PropTypes.string.isRequired,
  }),
  pricingPlans: PropTypes.arrayOf(PRICING_PLAN_PROP_TYPE).isRequired,
}

LandingPage.defaultProps = {
  ...PAGE_DEFAULT_PROPS,
  blocks: [],
  closingModule: null,
  metaTags: {},
  appearance: APPEARANCE.LIGHT,
  exitIntentSurvey: null,
  pricingPlans: [],
}

LandingPage.displayName = 'LandingPage'

export default LandingPage
