import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { unitize } from 'utils/unitize'

const areas = {
  xsmall: 8,
  small: 16,
  medium: 32,
  large: 48,
  xlarge: 64,
}

const availableAreas = [...Object.keys(areas), 'full']

const makeArea = (property, areaSize) => {
  if (areaSize === 'remaining') {
    return 'flex: 1;'
  }
  if (areaSize && availableAreas.indexOf(areaSize) === -1) {
    return `${property}: ${areaSize};`
  }
  if (areaSize === 'full') {
    return `${property}: 100%;`
  }
  return areaSize && `${property}: ${unitize(areas[areaSize])};`
}

const padPropsToCss = css`
  ${props => props.pad && `padding: ${unitize(props.pad)}`};
  ${props =>
    props.padX &&
    `padding-left: ${unitize(props.padX)}; padding-right: ${unitize(
      props.padX
    )}`};
  ${props =>
    props.padY &&
    `padding-top: ${unitize(props.padY)}; padding-bottom: ${unitize(
      props.padY
    )}`};
  ${props => props.padTop && `padding-top: ${unitize(props.padTop)}`};
  ${props => props.padBottom && `padding-bottom: ${unitize(props.padBottom)}`};
  ${props => props.padLeft && `padding-left: ${unitize(props.padLeft)}`};
  ${props => props.padRight && `padding-right: ${unitize(props.padRight)}`};
`

const Container = styled.div`
  flex-shrink: 0;
  min-width: 0;
  background-color: ${props => props.backgroundColor || 'inherit'};
  ${props => makeArea('width', props.width)};
  ${props => makeArea('height', props.height)};
  ${padPropsToCss};
`
Container.propTypes = {
  pad: PropTypes.number,
  padX: PropTypes.number,
  padY: PropTypes.number,
  padTop: PropTypes.number,
  padRight: PropTypes.number,
  padBottom: PropTypes.number,
  padLeft: PropTypes.number,
  backgroundColor: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

Container.defaultProps = {
  pad: null,
  padX: null,
  padY: null,
  padTop: null,
  padRight: null,
  padBottom: null,
  padLeft: null,
  backgroundColor: null,
  height: null,
  width: null,
}

Container.displayName = 'Container'

export { Container }
