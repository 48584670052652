import Entry from './entry-model'

class Audio extends Entry {
  get file() {
    return this.fields.file
  }

  get url() {
    return this.file.url
  }

  get contentType() {
    return this.file.contentType
  }

  get title() {
    return this.fields.title
  }

  toJSON() {
    return {
      title: this.title,
      contentType: this.contentType,
      url: this.url,
    }
  }
}

export default Audio
