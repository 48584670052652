import React, { memo, useCallback, useMemo } from 'react'
import { useEnvContext } from 'components/env-context'
import {
  generateState,
  setSignupCookies,
} from 'components/okta-signup-page/helpers'
import { getSocialIcon } from 'components/okta-signup-page/social-buttons/social-button/helpers'
import { getSocialSignupRedirectUrl } from 'components/okta-widget/helpers'
import {
  SocialButtons,
  Button,
  Link,
  Container,
  Divider,
  Icon,
} from 'components/social-signup-action-bar/social-signup-action-bar.styles'
import { useAppContext } from 'components/app-context'

import { trackClick } from './utils'

const SocialSignupActionBar = () => {
  const oktaState = useMemo(() => generateState(), [])
  const { device } = useAppContext()

  const {
    GOOGLE_SOCIAL_IDP_ID,
    MICROSOFT_SOCIAL_IDP_ID,
    OKTA_CLIENT_ID,
    OKTA_DOMAIN,
  } = useEnvContext()
  const handleClick = useCallback(
    (social?: string) => () => {
      switch (social) {
        case 'microsoft':
          trackClick('microsoft_signup')
          trackClick('social_signup')
          break
        case 'google':
          trackClick('google_signup')
          trackClick('social_signup')
          break
        case 'email':
          trackClick('email_signup')
          trackClick('sign_up')
          break
        default:
          trackClick('sign_up')
          break
      }

      if (social && social !== 'email') {
        setSignupCookies({ provider: social, device })
      }
    },
    [device]
  )

  return (
    <Container>
      <SocialButtons>
        <Button
          href={getSocialSignupRedirectUrl(
            GOOGLE_SOCIAL_IDP_ID,
            OKTA_CLIENT_ID,
            OKTA_DOMAIN,
            oktaState
          )}
          onClick={handleClick('google')}
        >
          <Icon alt='Signup with Google' src={getSocialIcon('google')} />
          Sign up with Google
        </Button>
        <Button
          href={getSocialSignupRedirectUrl(
            MICROSOFT_SOCIAL_IDP_ID,
            OKTA_CLIENT_ID,
            OKTA_DOMAIN,
            oktaState
          )}
          onClick={handleClick('microsoft')}
        >
          <Icon alt='Signup with Microsoft' src={getSocialIcon('microsoft')} />
          Sign up with Microsoft
        </Button>
      </SocialButtons>
      <Divider data-content='or' />
      <Link href='/signup/#email' onClick={handleClick('email')}>
        Sign up with email
      </Link>
    </Container>
  )
}

SocialSignupActionBar.defaultProps = {}

export default memo(SocialSignupActionBar)
