/**
  Heights are used to ensure the lazy loaded embed doesn't cause
  any layout shift. Please adjust if the design changes
 */
export const CTA_HEIGHT = 88
export const TYPEFORM_EMBED_HEIGHT = {
  xs: 608,
  sm: 672,
  lg: 728,
  xl: 728,
}
