import {
  CAROUSEL_SECTION,
  CTA_SECTION,
  GRID_SECTION,
  HEADLINE_MODULE,
  IMAGE_ACCORDION_MODULE,
  MEDIA_TEXT_MODULE,
  LIST,
  LOGOS_MODULE,
  MEDIA_BIG_MODULE,
  RAW_HTML_SECTION,
  RICH_TEXT_MODULE,
  RICH_TEXT_SECTION,
  TESTIMONIAL_MODULE,
  TESTIMONIALS_MODULE,
  TRUSTPILOT_SECTION,
  TYPEFORM_EMBED_MODULE,
  TYPEFORM_SECTION,
  TYPEFORM_TEMPLATE_MODULE,
  MLP_FAQS_MODULE,
  SCROLLING_CARDS_MODULE,
  REFER_A_FRIEND_LINK_GENERATOR_SECTION,
  MLP_ENTERPRISE_SECTION,
  RATINGS_MODULE,
  PRICING_PLANS_MODULE,
  CTA_MODULE,
  CONTENT_GRID_MODULE,
  CONTEXT_SWITCHER_MODULE,
} from 'constants/content-types'

import dynamic from 'next/dynamic'

export const COMPONENT_MAP = {
  // #region Modules
  [CONTENT_GRID_MODULE]: dynamic(
    () => import('components/modules/content-grid-module')
  ),
  [CONTEXT_SWITCHER_MODULE]: dynamic(
    () => import('components/modules/context-switcher-module')
  ),
  [CTA_MODULE]: dynamic(() => import('components/modules/cta-module')),
  [HEADLINE_MODULE]: dynamic(
    () => import('components/modules/headline-module')
  ),
  [IMAGE_ACCORDION_MODULE]: dynamic(
    // @ts-ignore We haven't yet migrated this module to TS
    () => import('components/modules/image-accordion-module')
  ),
  [MEDIA_TEXT_MODULE]: dynamic(
    () => import('components/modules/media-text-module')
  ),
  [LOGOS_MODULE]: dynamic(() => import('components/modules/logos-module')),
  [MEDIA_BIG_MODULE]: dynamic(
    () => import('components/modules/media-big-module')
  ),
  [MLP_FAQS_MODULE]: dynamic(() => import('components/modules/faq-module')),
  [RICH_TEXT_MODULE]: dynamic(
    // @ts-ignore We haven't yet migrated this module to TS
    () => import('components/modules/rich-text-module')
  ),
  [SCROLLING_CARDS_MODULE]: dynamic(
    () => import('components/landing-page/scrolling-cards-module')
  ),
  [TESTIMONIAL_MODULE]: dynamic(
    // @ts-ignore We haven't yet migrated this module to TS
    () => import('@typeform/ginger/dist/components/testimonial-module')
  ),
  [TESTIMONIALS_MODULE]: dynamic(
    // @ts-ignore We haven't yet migrated this module to TS
    () => import('components/modules/testimonials-module')
  ),
  [TYPEFORM_EMBED_MODULE]: dynamic(
    () => import('components/modules/typeform-embed-module')
  ),
  [TYPEFORM_TEMPLATE_MODULE]: dynamic(
    () => import('components/modules/typeform-template-module')
  ),
  [RATINGS_MODULE]: dynamic(() => import('components/modules/ratings-module')),
  [PRICING_PLANS_MODULE]: dynamic(
    () => import('components/pricing-plans-module-for-landing-pages')
  ),
  // #endregion

  // #region Sections
  [CAROUSEL_SECTION]: dynamic(
    () => import('components/landing-page/carousel-section')
  ),
  [CTA_SECTION]: dynamic(() => import('components/landing-page/cta-section')),
  [GRID_SECTION]: dynamic(() => import('components/landing-page/grid-section')),
  [RAW_HTML_SECTION]: dynamic(
    () => import('components/landing-page/raw-html-section')
  ),
  [RICH_TEXT_SECTION]: dynamic(
    () => import('components/landing-page/rich-text-section')
  ),
  [TRUSTPILOT_SECTION]: dynamic(
    () => import('components/landing-page/trustpilot-section')
  ),
  [TYPEFORM_SECTION]: dynamic(
    () => import('components/landing-page/typeform-section')
  ),
  [REFER_A_FRIEND_LINK_GENERATOR_SECTION]: dynamic(
    () =>
      import('components/landing-page/refer-a-friend-link-generator-section')
  ),
  // #endregion

  // #region Other
  [LIST]: dynamic(() => import('components/landing-page/list')),
  [MLP_ENTERPRISE_SECTION]: dynamic(
    () => import('components/enterprise-section')
  ),
  // #endregion
}

export const HEADLINE_MODULE_SIZE = 2
export const ABOVE_THE_FOLD_BLOCKS = 1
