import { isContentfulImage } from 'utils/contentful'

import Entry from './entry-model'
import { TImage, TImageEntry } from './image-model.types'

class Image extends Entry<TImageEntry> {
  get asset() {
    return this.fields.file
  }

  get url() {
    let url = this.asset?.url
    if (typeof url === 'string') {
      if (url.startsWith('//')) {
        url = `https:${url}`
      }

      if (isContentfulImage(url)) {
        const urlWithOptimizationParams = new URL(url)

        const skipConversion = ['.webp', '.svg'].some(ext =>
          urlWithOptimizationParams.pathname.endsWith(ext)
        )

        if (!skipConversion) {
          urlWithOptimizationParams.searchParams.append('fm', 'webp')
          urlWithOptimizationParams.searchParams.append('q', '75')
        }
        return urlWithOptimizationParams.toString()
      }
    }

    return url ?? ''
  }

  get width() {
    return this.asset?.details?.image?.width
  }

  get height() {
    return this.asset?.details?.image?.height
  }

  get contentType() {
    return this.asset?.contentType ?? ''
  }

  get title() {
    return this.fields.title
  }

  get description() {
    return this.fields?.description ?? ''
  }

  get alt() {
    return this.title || this.description || ''
  }

  get role() {
    return !this.alt ? 'presentation' : ''
  }

  toJSON(): TImage {
    return {
      title: this.title,
      description: this.description,
      width: this.width,
      height: this.height,
      contentType: this.contentType,
      url: this.url,
      alt: this.alt,
      role: this.role,
    }
  }
}

export default Image
