import { CELLO_UCC } from 'constants/query-strings'
import { CELLO_PATH } from 'constants/url'

import React, { memo, useEffect } from 'react'
import useCookieSettings from 'hooks/use-cookie-settings'
import {
  Container,
  GlobalStyles,
} from 'components/cello-legal-banner/cello-legal-banner.styles'
import Text, {
  SIZE,
} from '@typeform/ginger/dist/components/typography/text/next'
import { useRouter } from 'next/router'
import cookies from 'utils/cookies'
import useResizeObserver from 'hooks/use-resize-observer'
import Link from 'components/atoms/link'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

import {
  TF_COOKIE_POLICY,
  CELLO_PRIVACY_POLICY,
  CELLO_TERMS_OF_SERVICE,
} from './constants'

const CelloLegalBanner = () => {
  const { asPath, query } = useRouter()
  const hasUCC = !!query[CELLO_UCC]
  const isActive = asPath.startsWith(CELLO_PATH)
  const { ready, areAnalyticsCookiesAllowed } = useCookieSettings()
  const { ref, offsetHeight } = useResizeObserver()

  useEffect(() => {
    if (isActive && ready && areAnalyticsCookiesAllowed && hasUCC) {
      cookies.set('cello-referral', query.ucc)
    }
  }, [areAnalyticsCookiesAllowed, hasUCC, isActive, query.ucc, ready])

  if (!isActive) {
    return null
  }

  return (
    <>
      <Container ref={ref}>
        <Text size={SIZE.XS}>
          Powered by Cello. By clicking “Get started - it’s free, Sign up” or on
          any of the paid plans you accept tracking to Typeform’s referral
          program by means of Cello’s cookies, as listed in the&nbsp;
          <Link href={TF_COOKIE_POLICY} color={NeutralColors.White}>
            Cookie Policy
          </Link>
          . Use of this service, including rewards and discounts, are subject to
          the Cello&nbsp;
          <Link href={CELLO_TERMS_OF_SERVICE} color={NeutralColors.White}>
            terms of service
          </Link>
          &nbsp;and&nbsp;
          <Link href={CELLO_PRIVACY_POLICY} color={NeutralColors.White}>
            privacy policy
          </Link>
          .
        </Text>
      </Container>

      <GlobalStyles offsetHeight={offsetHeight} />
    </>
  )
}

export default memo(CelloLegalBanner)
