import styled, { createGlobalStyle } from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { spacing } from '@typeform/ginger/dist/util/spacing'

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: ${NeutralColors.Ink};
  color: ${NeutralColors.White};
  z-index: 2;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing(6, 4)};
`

export const GlobalStyles = createGlobalStyle<{
  offsetHeight: number
}>`
  body {
    padding-bottom: ${({ offsetHeight }) => offsetHeight - 1}px;
  }

  .cookie-consent-area {
    display: none;
  }
`
