import React from 'react'
import { useInView } from 'react-intersection-observer'

import { Trigger } from './lazy-load.styles'

type TLazyLoad = {
  children?: React.ReactNode
  fallback?: React.ReactNode
  threshold?: number | number[]
  rootMargin?: string
}

const LazyLoad = ({
  children,
  fallback,
  threshold = 0,
  rootMargin = '0px',
}: TLazyLoad) => {
  const { ref, inView } = useInView({
    threshold,
    rootMargin,
    triggerOnce: true,
  })

  return (
    <>
      {inView ? children : <Trigger ref={ref} />}
      {fallback && <noscript>{fallback}</noscript>}
    </>
  )
}

export default React.memo(LazyLoad)
