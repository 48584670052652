import styled from 'styled-components'
import { memo } from 'react'

import { TEmbed } from './types'

const StyledEmbedModule = styled.div<{
  aspectRatio?: string
}>`
  position: relative;
  width: 100%;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio || '16 / 9'};
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`

const ContentfulMediaEmbed = ({ embedUrl, aspectRatio }: TEmbed) => (
  <StyledEmbedModule aspectRatio={aspectRatio}>
    <StyledIframe src={embedUrl} loading='lazy' />
  </StyledEmbedModule>
)

export default memo(ContentfulMediaEmbed)
